<template>
  <div>
    <b-tabs class="shadow p-2">
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card
          v-if="programs.length < 1"
          class="text-center"
        >
          No programs found under your organizations!
        </b-card>
        <div class="d-flex  align-items-center justify-content-between  ">
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
          </div>
          <div>
            <button
              :class="`btn btn-cart rounded ${view ? 'btn-primary': 'btn-outline-primary'}`"
              type="button"
              @click="updateView(true)"
            >
              Grid
            </button>
            <button
              :class="`btn btn-cart rounded ml-1 ${!view ? 'btn-primary': 'btn-outline-primary'}`"
              type="button"
              @click="updateView(false)"
            >
              List
            </button>
          </div>
        </div>
        <section
          v-if="!view"
          class="mt-2"
        >
          <b-card class="">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :pagination-options="pagination"
            />
          </b-card>
        </section>
        <section
          v-if="view"
          class="grid-view"
        >
          <b-card
            v-for="(program, j) in programs"
            :key="j"
            class="ecommerce-card"
            header-tag="header"
            tag="article"
          >
            <b-img
              class="rounded"
              src="https://picsum.photos/600/300/?image=25"
              alt="image"
              img-top
              style="width: 100%; position: relative;"
            />
            <b-badge
              style="position: absolute; top: 95px; right: 25px;color: black;"
              variant="light"
            >
              Event Type
            </b-badge>
            <b-badge
              style="position: absolute; top: 125px; right: 25px;color: black;"
              variant="light"
            >
              Free
            </b-badge>
            <b-badge
              style="position: absolute; top: 95px; left: 25px;color: black;"
              variant="light"
            >
              E
            </b-badge>
            <template #header>
              <div class="d-flex align-items-center">
                <b-img
                  rounded
                  alt="Rounded image"
                  src="https://shorturl.at/kxFUX"
                  style="height: 30px; width: 30px; margin-right: 10px;"
                />
                <b-card-text> <strong>Startup Name</strong></b-card-text>
              </div>
            </template>
            <div class="mt-2 mb-1">
              <div>
                <b-card-text style="font-size: large; margin-top: 15px; margin-bottom: 5px;">
                  <strong> {{ program.title }} </strong>
                </b-card-text>
              </div>
              <div class="d-flex align-items-baseline   justify-content-between">
                <b-card-text><strong>Date : {{ beginDate(program.begin_date) }}</strong></b-card-text>
                <b-badge class="badge badge-light-success">
                  Online
                </b-badge>
              </div>
              <b-card-sub-title class="mt-1">
                Some quick example text to build on the card title and make up the bulk of the card's content.
              </b-card-sub-title>
            </div>
            <div class="item-options text-center d-flex">
              <b-link
                class="btn btn-wishlist btn-light mr-1 rounded"
                variant="light"
                @click="$bvModal.show('view-event-modal')"
              >
                <!-- :to="`/startup-portal/programs/${program.id}`" -->
                <span>View Details</span>
              </b-link>
              <b-link
                :to="`/mentor-portal/events/select/${program.id}`"
                class="btn btn-primary btn-cart ml-1 rounded"
              >
                <span>Open</span>
              </b-link>
            </div>
          </b-card>
        </section>
      </b-overlay>
    </b-tabs>
    <b-modal
      id="view-event-modal"
      title="Event Title"
      hide-footer
      size="lg"
    >
      <b-img
        src="https://picsum.photos/600/300/?image=25"
        style="width: 100%;"
      />
      <b-table
        stacked
        bordered
        :items="items"
      >
        <template #cell(url)="data">
          <b-link
            :href="prependHttps(data.item.url)"
            target="_blank"
          >{{ data.item.url }}
          </b-link>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  // BCardBody,
  BCardSubTitle,
  BCardText,
  // BCardTitle,
  BLink, BOverlay,
  BTabs,
  // BCol,
  BBadge, BImg, BModal, BTable,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { VueGoodTable } from 'vue-good-table'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    // BCardBody,
    VueGoodTable,
    // BCardTitle,
    BModal,
    BCardSubTitle,
    BOverlay,
    BTable,
    BLink,
    BBadge,
    // BCol,
    BImg,
  },
  data() {
    return {
      programs: [],
      rows: [],
      columns: [
        {
          label: 'Organization',
          field: 'organization',
        },
        {
          label: 'Event Title',
          field: 'event_title',
        },
        {
          label: 'Event Type',
          field: 'event_type',
        },
        {
          label: 'Begin Date',
          field: 'begin_date',
        },
        {
          label: 'End Date',
          field: 'end_date',
        },
        {
          label: 'Mode',
          field: 'mode',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      view: true,
      items: [
        {
          'Organized By': 'xyz', 'Event Title': 'Startup India', 'Event Type': 'Online', 'Description ': 'this is a description this is a description this is a description this is a description this is a description this is a description this is a description', 'Start Date & Time': '12 sept 2023', 'End Date & Time': '24 sept 2023',
        },
      ],
      userOrgs: getUserData()
        .associatedOrgDetails

        .map(el => el.organization_id)
        .filter(n => n),
    }
  },
  methods: {
    beginDate(date) {
      const dateNew = new Date(date)
      const options = { day: 'numeric', month: 'long', year: 'numeric' }
      const formattedDate = dateNew.toLocaleDateString('en-US', options)
      return formattedDate
    },
    updateView(view) {
      this.view = view
    },
  },
  apollo: {
    programs: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
        {
          programs_basicinfo(where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_applicationformstables: {status: {_eq: "enabled"}, round_number: {_eq: 1}}, type: {_in: ["Pre-incubation Program", "Incubation Program", "Accelerator Program"]}}) {
            begin_date
            capacity
            description
            id
            image
            industry
            title
            type
            programs_applicationformstables(where: {round_number: {_eq: 1}, status: {_eq: "enabled"}}) {
              id
            }
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
